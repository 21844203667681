/* src/app/modules/gestion/common/ver-coti-resumen/resumen-lista-concepto/resumen-lista-concepto.component.scss */
.title {
  background-color: #646c9a !important;
}
.title p {
  color: #ffffff;
  padding: 0.5rem 0rem;
  font-size: 1.1em;
}
.title p small {
  text-transform: capitalize !important;
}
table.table thead {
  background-color: #f7f8fa !important;
  color: #646c9a;
  padding: 0.5rem 0rem;
  font: bold !important;
}
table.table tbody {
  color: #4a4a4a;
}
table.table td {
  border: black 1px solid;
}
table.table tr {
  border: black 1px solid;
}
table.table th {
  border: black 1px solid;
}
table thead th,
table thead td {
  font-weight: 500;
  border-bottom-width: 1px;
  padding-top: 5px;
  padding-bottom: 5px;
}
table th,
table td {
  padding: 3px 4px 3px 15px;
  vertical-align: top;
  border-top: 1px solid #ebedf2;
}
.content {
  background-color: #f7f8fa !important;
}
.content p {
  color: #646c9a;
  padding: 0.5rem 0rem;
}
/*# sourceMappingURL=resumen-lista-concepto.component.css.map */
