<table class="table p-0" aria-label="Tabla Carga">
	<thead *ngIf="dataSacCotizacionConcepto && dataSacCotizacionConcepto.length > 0">
		<tr>
			<th scope="col" class="content" style="width: 35%">
				<p>{{ dataGrupos.nombre }}</p>
			</th>
			<th scope="col" class="content" style="width: 10%"><p>Moneda</p></th>
			<th scope="col" class="content" style="width: 15%"><p>Unidad</p></th>
			<th scope="col" class="content" style="width: 15%"><p>Costo</p></th>
			<th scope="col" class="content" style="width: 15%"><p>Venta</p></th>
			<th scope="col" class="content" style="width: 15%"><p>Utilidad</p></th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let concepto of dataSacCotizacionConcepto">
			<td style="width: 35%">
				<p>{{ concepto.descripcion }}</p>
			</td>
			<td style="width: 10%">
				<p class="p-0">{{ concepto.tbMoneda?.codigo }}</p>
			</td>
			<td style="width: 15%">
				<p class="p-0">{{ concepto.tbUnidadMedida?.nombre }}</p>
			</td>
			<td style="width: 15%">
				<p>{{ convertToDecimal(concepto.importeCompra, 3) }}</p>
			</td>
			<td style="width: 15%">
				<p>{{ convertToDecimal(concepto.importeVenta, 3) }}</p>
			</td>
			<td style="width: 15%">
				<p>{{ convertToDecimal(concepto.importeUtilidad, 3) }}</p>
			</td>
		</tr>
	</tbody>
</table>
